import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

export const Footer: React.FC = (): JSX.Element => {
  return (
   <footer>
     <Container>
       <Row className="align-items-end">
         <Col lg={6}>
           <p className="color-white mb-3 fw-semibold">© 2020 Sandoz Farmacéutica, S.A</p>

           <nav>
            <ul>
              <li>
                <a href="/">Condiciones de uso</a>
              </li>
              <li>
                <a href="/">Política de cookies</a>
              </li>
              <li>
                <a href="/">Aviso legal</a>
              </li>
              <li>
                <a href="https://www.sandozfarma.es/politica-de-privacidad/" target="_blank" rel="noreferrer">Política de privacidad</a>
              </li>
              <li>
                <a href="/">Configuración de cookies</a>
              </li>
              <li>
                <a href="/">Protección de datos</a>
              </li>
            
            </ul>
           </nav>
         </Col>
         <Col lg={6}>
           <div className="d-flex justify-content-between align-items-center mb-3">
             <p className="color-white font-s ms-5 fw-normal">Esta página está dirigida a usuarios de España</p>
             <p className="font-s">
               <a href="/" className="color-white fw-semibold text-nowrap d-inline-flex align-items-center">Síguenos en Instagram  <img src="/assets/img/instagram.png" alt="Instagram" className="ms-2"/></a>
             </p>
           </div>

           <p className="fw-lighter font-s text-end mb-1">ES2004814385 | ES2004814385 | ES2004814385 | ES2004814385 | ES2004814385</p>
         </Col>
       </Row>
     </Container>
   </footer>
  );
};