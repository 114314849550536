import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { WelcomeComponent } from '../../components/home/WelcomeComponent';
import { routes } from '../../../router/constants';
import { HomeAppointments } from '../../components/appointment/HomeAppointments';
import { HomePatients } from './HomePatients';
import { useEffect, useState } from 'react';
import { useAuth } from '../../customHooks/useAuth';
import { useAppDispatch } from '../../../module/hooks';
import { checkTerms, confirmTerms } from '../../../module/reducers/user/slice';

export const HomePage = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth()
  const dispatch = useAppDispatch();

  const [showHome, setShowHome] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [termsData, setTermsData] = useState({text:'', file: ''});
  const [accepted, setAccepted] = useState(false);

  const getInnerData= () => {
    return {__html: termsData.text}
    
  }

  const acceptTerms = () => {
    dispatch(confirmTerms()).unwrap().then(() => {
      setShowHome(true)
      setShowTerms(false)
    })
  }

  useEffect(() => {

    dispatch(checkTerms()).unwrap().then(
      (resp) => {
        if(resp.message !== 'ok') {
          setShowTerms(true);
          setTermsData(resp.content);
        } else {
          setShowHome(true);
        }
      }
    )
    
  }, [])
  return (
    <Container className="pb-5">

      <Modal size='lg' show={showTerms}>
        <Modal.Header>
          <div className='w-100 '>
            <div className='w-100 justify-content-start'>
              <h3 className='font-xml fw-bold'>Accede a la Herramienta Pacientes+</h3>
            </div>
          
            
          </div>
          
        </Modal.Header>
       { termsData ? 
        <Modal.Body className='px-5 pt-3'>
          
          <div className="w-100 d-flex justify-content-between align-items-center">
              <p className='font-l fw-bold'>Términos y condiciones</p>
              <div className='color-prim'>
                <a href={baseUrl + '/' + termsData.file} target="_blank">Descargar</a>
                <i className='ssp-arrow-down font-m me-1'></i>
              </div>

          </div>

          <div className='d-flex align-items-center justify-content-between mb-2'>

            <div className='align-items-center' dangerouslySetInnerHTML={getInnerData()}>
            </div>
          </div>

         

          <hr className='mb-0' />
        </Modal.Body>:undefined}
          
          
        <Modal.Footer >

              <div className='w-100'>
                 
                <label>
                  <input type='checkbox' onChange={(ev) => setAccepted(ev.target.checked)}></input> 
                  <span className='ml-5'> Acepto términos y condiciones</span>
                </label>
              </div>
            

            
            <div  className='w-100 d-flex justify-content-center'>
              <Button disabled={!accepted} onClick={acceptTerms}>Confirmar y acceder</Button>
            </div>
            
          
        </Modal.Footer>
      </Modal>

      {showHome ? <Row>
        <Col xl={8} lg={8}>
          <WelcomeComponent />
          <HomePatients />
        </Col>
        <Col xl={4} lg={4} className="mt-lg-0 mt-4">
          {/* <div className="card mb-4 p-4">
            <h3 className="font-xml fw-bold">¿Quieres registrar una nueva medición?</h3>
            <div className="text-end mt-4">
              <Link className="btn prim " to={routes.newMedition}>Registrar medición</Link>
            </div>
          </div> */}
          <HomeAppointments />
        </Col>
      </Row> : undefined}
    </Container >

  )
}
