import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { initialRegisterValues, registerValidationSchema } from '../../../helpers/schemas/authSchema';
import { InputField } from '../../components/shared/fields';
import { useTranslation } from 'react-i18next';
import { BasicLoader } from '../../components/shared/loaders';
import { useAuth } from '../../customHooks/useAuth';
import { routes } from '../../../router/constants';

export const RegisterPage = () => {
  const { register } = useAuth()
  const { t } = useTranslation();

  return (
    <div className="login-page register">

      <Formik
        initialValues={initialRegisterValues}
        validationSchema={registerValidationSchema}
        onSubmit={(fields, actions) => register(fields, actions.setSubmitting)}
      >
        {
          (props) => {
            return (
              <Form>
                <Container fluid>
                  <Row>
                    <Col lg={12} className="ps-lg-5">
                      <div className="logo">
                        <img className="logo-ps" src="/assets/img/logo-ps.svg" alt="Logo Sandoz" />
                      </div>

                    </Col>
                  </Row>
                </Container>
                <Container>

                  <Row>
                    <Col lg={12} className="ps-lg-5">
                      <h1 className="fw-bold font-xxl my-4 pb-2 mt-5 pt-4">{t('register.register')}</h1>
                    </Col>

                    <Col lg={4} className="ps-lg-5">
                      <InputField {...props} type="text" label={t('user.name')} name="name" />
                      <InputField {...props} type="text" label={t('user.address')} name="address" />
                      <InputField {...props} type="text" label={t('user.owner_name')} name="owner_name" />
                      <InputField {...props} type="text" label={t('user.owner_surname')} name="owner_surname" />
                      <InputField {...props} type="text" label={t('user.telephone')} name="telephone" />
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4}>
                      <InputField {...props} type="text" label={t('user.cif')} name="cif" />
                      <InputField {...props} type="text" label={t('user.email')} name="email" />
                      <InputField {...props} type="password" label={t('user.password')} name="password" />
                      <InputField {...props} type="password" label={t('user.confirmPassword')} name="confirmPassword" />
                    </Col>
                    <Col lg={2}></Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="ps-lg-5">
                      <Button className="prim my-4" variant="none" type="submit" disabled={props.isSubmitting} >
                        {
                          props.isSubmitting ? <BasicLoader /> : t('register.register')
                        }
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col lg={12} className="ps-lg-5">
                      <p className="mb-4 fw-lighter">{t('register.haveAccount')} <Link to={routes.login} className="link fw-semibold ms-1 color-prim">{t('register.access')}</Link></p>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}
